<template>
    <div>
        <div :class="rowText">
            <div class="mr-2">
                <i class="mdi mdi-script-text-outline"></i>
            </div>
            <div :class="textRight">
                <span class="">
                    {{ Get_itemLuaChonDungHanhTrinh.MaSoLenh }}
                </span>
                <span class="">
                    ({{ Get_itemLuaChonDungHanhTrinh.TenTrangThaiLenh }})
                </span>
            </div>
        </div>
        <div :class="rowText">
            <div class="mr-2">
                <i class="mdi mdi-calendar-clock"></i>
            </div>
            <div :class="textRight">
                {{
                    Get_itemLuaChonDungHanhTrinh.GioXuatBen
                        ? Get_itemLuaChonDungHanhTrinh.GioXuatBen.substr(0, 5)
                        : ""
                }}
            </div>
            <span class="mx-1"> - </span>
            <div class="mr-2">
                <i class="mdi mdi-card-bulleted-outline"></i>
            </div>
            <div :class="textRight">
                {{
                    Get_itemLuaChonDungHanhTrinh.BienSoXe
                        ? Get_itemLuaChonDungHanhTrinh.BienSoXe
                        : ""
                }}
            </div>
        </div>
        <div :class="rowText">
            <div class="mr-2">
                <i class="mdi mdi-road-variant"></i>
            </div>
            <div :class="textRight">
                {{ `${Get_itemLuaChonDungHanhTrinh.TenTuyen}` }}
            </div>
        </div>
        <div :class="rowText">
            <div class="mr-2">
                <i class="mdi mdi-road-variant"></i>
            </div>
            <div :class="textRight">
                {{ `(${Get_itemLuaChonDungHanhTrinh.MaTuyen})` }}
            </div>
        </div>

        <DxValidationGroup ref="formValidation">
            <div class="pt-3 pb-4">
                <span class="font-12">Chi tiết sự cố (*)</span>
                <DxTextBox
                    validationMessageMode="always"
                    styling-mode="underlined"
                    v-model="lyDoDungHanhTrinh"
                    :showClearButton='true'
                >
                    <DxValidator>
                        <DxRequiredRule message="Chi tiết sự cố không được bỏ trống!" />
                        <DxStringLengthRule
                            :max="128"
                            message="Chi tiết sự cố không được vượt quá 128 ký tự!"
                        />
                    </DxValidator>
                </DxTextBox>
            </div>
        </DxValidationGroup>
    </div>
</template>
<script>
import { DxValidationGroup, DxValidator, DxButton, DxTextBox } from "devextreme-vue";
import { DxRangeRule } from "devextreme-vue/validator";
import { DxDropDownBox } from "devextreme-vue/drop-down-box";
import {
    DxRequiredRule,
    DxStringLengthRule,
    DxCustomRule,
} from "devextreme-vue/data-grid";

export default {
    layout: "application",
    components: {
        DxValidationGroup,
        DxValidator,
        DxButton,
        DxTextBox,
        DxRangeRule,
        DxDropDownBox,
        DxRequiredRule,
        DxStringLengthRule,
        DxCustomRule,
    },
    data() {
        return {
            rowText: "row align-center row-text justify-center",
            rowLastText: "row align-center row-text",
            textRight: " font-medium",
            lyDoDungHanhTrinh: "Hỏng xe",
        };
    },
    computed: {
        Get_itemLuaChonDungHanhTrinh() {
            return this.$store.state.DVVTLenh.itemLuaChonDungHanhTrinh;
        },
    },
};
</script>
<style scoped></style>
<style lang="scss" scoped></style>
